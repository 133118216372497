/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #313131;
    color: #f3f3f3;
}

h1 {
    text-align: center;
    margin: 20px 0;
}

.container {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    background: #4d4c4c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

form {
    display: flex;
    flex-direction: column;
}

input, textarea, button {
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

button {
    background-color: #0051ff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0071b3;
}

.message {
    margin: 10px 0;
    color: green;
}

.error {
    color: red;
}

/* Dashboard Styling */
.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard .application-item {
    background: #313131;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
}

.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
}

.dashboard .status-box {
    background: #313131;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 32%; /* Adjust width to fit 3 boxes in a row */
    box-sizing: border-box;
}

.dashboard .status-box h3 {
    text-align: center;
    margin-bottom: 10px;
}

.dashboard .status-box ul {
    list-style: none;
    padding: 0;
}

.dashboard .status-box li {
    margin: 5px 0;
}

.dashboard .status-box .buttons {
    display: flex;
    justify-content: space-between; /* Space out the buttons evenly */
    width: 100%; /* Ensure the buttons take the full width of the container */
}

.dashboard .status-box .approve,
.dashboard .status-box .deny {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    width: 45%; /* Ensure both buttons take up a similar amount of space */
}

.dashboard .status-box .approve {
    background-color: #28a745; /* Green for approve */
    color: white;
}

.dashboard .status-box .deny {
    background-color: #dc3545; /* Red for deny */
    color: white;
}

.dashboard .status-box .approve:hover {
    background-color: #218838; /* Darker green on hover */
}

.dashboard .status-box .deny:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Adjusting for pending page */
.application-card .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.application-card .approve,
.application-card .deny {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    width: 45%;
}

.application-card .approve {
    background-color: #28a745; /* Green for approve */
    color: white;
}

.application-card .deny {
    background-color: #dc3545; /* Red for deny */
    color: white;
}

.application-card .approve:hover {
    background-color: #218838; /* Darker green on hover */
}

.application-card .deny:hover {
    background-color: #c82333; /* Darker red on hover */
}

.logout-container {
    margin-top: 20px;
    text-align: center;
}

.logout-button {
    background-color: #ff5555;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #ff3333;
    opacity: 0.8;
}
